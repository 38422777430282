import Image from 'next/image'
import { useRouter } from 'next/router'
import { observer, Observer } from 'mobx-react'
import { isEmpty } from 'lodash'

import { useAppState } from '@/stores'
import { AssetsIcons } from '@/config/constants/assets'
import { Market } from '@/stores/types'
import Tooltip from '@/components/Popup/tooltip'
import VestButton from '@/components/VestButton'
// import APR from '@/components/Common/APR'
import Skeleton from '@/components/Common/Skeleton'
import decodingYieldsMatrixApyRender from '@/views/YieldMatrix/decodingYieldsMatrixApyRender'
// import LendIcon from '@/public/image/LENDLE.png'
// import LightningIcon from '@/public/svg/icon-lightning.svg'
import { numberFormat } from '@/utils/format'
// import APY from '@/components/Common/APY'
import SupplyAPY from '@/components/Common/SupplyAPY'
import BorrowAPY from '@/components/Common/BorrowAPY'

type MarketColumnProps = {
  markets: Record<Lowercase<string>, Market>
  yieldMatrixData?: any
}

const MarketColumnMobile: React.FunctionComponent<MarketColumnProps> = ({ markets, yieldMatrixData }) => {
  const router = useRouter()
  const {
    walletAccountStore: { methApy, reserves }
  } = useAppState()

  const handleClick = (item: Market) => {
    router.push({
      pathname: '/marketdetail',
      query: {
        asset: item.symbol,
        contract: item.address
      }
    })
  }

  return (
    <div className="card overflow-visible md:hidden">
      {isEmpty(markets)
        ? Array(5)
            .fill({})
            .map(
              (_, index) => <MarketColumnSkeleton key={'MarketTableSkeleton' + index} /> // eslint-disable-line
            )
        : Object.entries(markets).map(([key, market], index) => {
            const { symbol, address } = market
            const reserve = !isEmpty(reserves) ? reserves[address] : null
            const reserveSupplied = Number(market.supplied.amount) / 10 ** market.supplied.decimals
            const reserveSuppliedRC = reserveSupplied * market.price
            const reserveBorrowed = Number(market.borrowed.amount) / 10 ** market.borrowed.decimals
            const reserveBorrowedRC = reserveBorrowed * market.price
            const yieldLoopItem = yieldMatrixData
              ? yieldMatrixData
                  .find((row) => row.keyRow === symbol)
                  .valueRow.find((token) => token.quoteToken === symbol)
              : null
            const yieldLongShortItem = yieldMatrixData
              ? yieldMatrixData
                  .find((row) => row.keyRow === symbol)
                  .valueRow.reduce((prev, current) => (prev.netApy > current.netApy ? prev : current))
              : null

            return (
              <div
                role="button"
                key={key}
                className="mb-0 w-full first:rounded-t-base last:rounded-b-base hover:bg-white-200 md:mb-3"
                onKeyDown={() => handleClick(market)}
                tabIndex={index}
                onClick={() => handleClick(market)}
              >
                <div className="px-4 py-3">
                  <div className="mb-4 flex items-center justify-between text-sm font-normal md:mb-3">
                    <div className="flex items-center">
                      {symbol && (
                        <Image src={AssetsIcons[symbol] || AssetsIcons.DEFAULT} alt={symbol} width={40} height={40} />
                      )}
                      <div className="ml-2">
                        <div className="flex-colum ml-2">
                          <div className="text900 font-bold">{symbol}</div>
                          {/* <div className="text600 text-xs font-semibold">
                            ${' '}
                            {numberFormat(market.price, {
                              minDigits: market.price < 10 ? 4 : 0,
                              maxDigits: market.price < 10 ? 4 : 0,
                              isSymbolHide: true
                            })}
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-col justify-center text-base font-normal">
                      <p className="text400 text-xs">Utilization Rate</p>
                      <div className=" items-center">
                        <div className="flex flex-wrap items-center justify-end">
                          {!isEmpty(reserve) ? (
                            <div className="text600 ml-2 text-xs">
                              {reserve?.borrowed?.amount && reserve?.supplied?.amount
                                ? numberFormat(
                                    (Number(reserve.borrowed.amount) * 100) / Number(reserve.supplied.amount)
                                  )
                                : '0,00'}{' '}
                              %
                            </div>
                          ) : (
                            <Skeleton className="!h-5 rounded-full" text={16} />
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="mb-3 flex items-center justify-between text-base font-normal">
                    <p className="text600">Market size</p>
                    <div className=" items-center">
                      <div className="flex flex-wrap items-center justify-end">
                        {/* <div className="text900 font-semibold">
                          {numberFormat(Number(reserveSupplied) + Number(reserveBorrowed), {
                            minDigits: reserveSupplied < 100 ? 2 : 0,
                            maxDigits: reserveSupplied < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div> */}
                        <div className="text900 ml-2">
                          ${' '}
                          {numberFormat(reserveSuppliedRC + reserveBorrowedRC, {
                            maxDigits: reserveSuppliedRC < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="items-top flex justify-between text-base font-normal md:mb-3">
                      <p className="text600">Supply APY</p>
                      <SupplyAPY contractAddress={address} symbol={symbol} positionType="mobile" />
                      {/* <div className="text900 flex flex-col items-end text-right">
                        <div>
                          <APY contractAddress={address} type="supply" />%
                        </div>
                        <div className="bg100 text700 group relative ml-1 flex items-center rounded-full px-2 py-0.5 text-xs font-medium">
                          <div className="mr-1 flex items-center">
                            <Image src={LendIcon} width={12} height={12} alt="LENDLE icon" />
                          </div>
                          <div>
                            <APR contractAddress={address} type="supply" />
                          </div>
                          <div className="">% APR</div>
                          <div className="absolute bottom-0 mb-6 hidden flex-col justify-center pb-4 group-hover:flex">
                            <span className="whitespace-no-wrap relative z-10 w-96 rounded-xl border border-colorPrimaryDark bg-white-1 p-2 text-xs leading-none text-textGray-600 shadow-lg dark:text-textGray-900">
                              Participating in this Lendle market gives rewards in LEND tokens. The APR is just a
                              representation on how these rewards would be during a 1-year period
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* {symbol.toLowerCase().includes('meth') && (
                      <div className="mb-2 mt-1 flex justify-end text-xs">
                        <div className="text700 font-normal">mETH APY</div>
                        <LightningIcon height="18px" width="18px" />
                        <div className="text900 font-medium">
                          {numberFormat(methApy, { maxDigits: 2 })}
                          <span className="">%</span>
                        </div>
                      </div>
                    )} */}
                  </div>
                  {/* <div className="mb-2">
                    <div className="items-top flex justify-between text-base font-normal md:mb-3">
                      <p className="text600">Loop APY</p>
                      {yieldMatrixData && (
                        <Tooltip
                          type="infoModal"
                          cloudStyle="top-1/2 right-24 underline-offset-1"
                          arrowStyle="hidden"
                          content={decodingYieldsMatrixApyRender(yieldLoopItem)}
                        >
                          <span className="text900 font-semibold">{numberFormat(yieldLoopItem.netApy)}%</span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="items-top flex justify-between text-base font-normal md:mb-3">
                      <p className="text600">Long/Short APY</p>
                      {yieldMatrixData && (
                        <Tooltip
                          type="infoModal"
                          cloudStyle="top-1/2 right-24 underline-offset-1"
                          arrowStyle="hidden"
                          content={decodingYieldsMatrixApyRender(yieldLongShortItem)}
                        >
                          <span className="text900 font-semibold">
                            Up to {numberFormat(yieldLongShortItem.netApy)}%
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div> */}
                  <div className="items-top mb-2 flex justify-between text-base font-normal md:mb-3">
                    <p className="text600">Borrow APY</p>
                    <BorrowAPY contractAddress={address} positionType="mobile" />
                    {/* <div className="text900 flex flex-col items-end text-right">
                      <div>
                        <APY contractAddress={address} type="borrow" />%
                      </div>
                      <div className="bg100 text700 group relative ml-1 flex items-center rounded-full px-2 py-0.5 text-xs font-medium">
                        <div className="mr-1 flex items-center">
                          <Image src={LendIcon} width={12} height={12} alt="LENDLE icon" />
                        </div>
                        <div>
                          <APR contractAddress={address} type="borrow" />
                        </div>
                        <div className="">% APR</div>
                        <div className="absolute bottom-0 mb-6 hidden flex-col justify-center pb-4 group-hover:flex">
                          <span className="text900 whitespace-no-wrap relative z-10 w-96 rounded-xl border border-colorPrimaryDark bg-white-1 p-2 text-xs leading-none shadow-lg">
                            Participating in this Lendle market gives rewards in LEND tokens. The APR is just a
                            representation on how these rewards would be during a 1-year period
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="mb-2 flex items-center justify-between text-base font-normal">
                    <p className="text600">Total borrowed</p>
                    <div className=" items-center">
                      <div className="flex flex-wrap items-center justify-end">
                        {/* <div className="text900 font-semibold">
                          {numberFormat(Number(reserveBorrowed), {
                            minDigits: reserveBorrowed < 100 ? 2 : 0,
                            maxDigits: reserveBorrowed < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div> */}
                        <div className="text900 ml-2">
                          ${' '}
                          {numberFormat(Number(reserveBorrowedRC), {
                            minDigits: reserveBorrowedRC < 100 ? 2 : 0,
                            maxDigits: reserveBorrowedRC < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="mb-2 h-[40px] w-full">
                    <Observer>
                      {() => <VestButton reserve={address} className="!h-[40px] !w-full text-xs font-medium" />}
                    </Observer>
                  </div> */}
                </div>
              </div>
            )
          })}
    </div>
  )
}

export default observer(MarketColumnMobile)

const MarketColumnSkeleton = () => (
  <div className="flex flex-col">
    {Array(6)
      .fill({})
      .map((_, index) => (
        <div
          className="box-border flex w-full flex-col md:hidden"
          key={'skeleton' + index} // eslint-disable-line
        >
          <div className="px-4 py-3">
            <div className="flex w-full flex-col gap-[15px]">
              <div className="mb-[3px] flex h-12 items-center justify-between">
                <div>
                  <Skeleton className="mr-4 h-[40px] w-[40px] rounded-full" text={10} />
                  <Skeleton className="h-4" text={16} />
                </div>
                <div className="ml-auto flex flex-col gap-2">
                  <Skeleton className="!my-0 h-4 rounded-second" text={24} classNameWrapper="ml-auto" />
                  <Skeleton className="ml-auto h-4 text-right" text={12} classNameWrapper="ml-auto" />
                </div>
              </div>
              <Skeleton className="!my-0 h-4" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-4" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-4" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-4" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-4" classNameWrapper="max-w-full" />
              <Skeleton className="!my-0 h-4" classNameWrapper="max-w-full" />
              <div className="mb-2 flex justify-between gap-4">
                <Skeleton className="!my-0 h-[40px] rounded-base" classNameWrapper="max-w-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
  </div>
)
