import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import { observer, Observer } from 'mobx-react'
import { isEmpty } from 'lodash'

import { useAppState } from '@/stores'
import { AssetsIcons } from '@/config/constants/assets'
import { Market } from '@/stores/types'
import Tooltip from '@/components/Popup/tooltip'
import VestButton from '@/components/VestButton'
// import Tooltip from '@/components/Popup/tooltip'
// import APR from '@/components/Common/APR'
// import APY from '@/components/Common/APY'
import Skeleton from '@/components/Common/Skeleton'
import SupplyAPY from '@/components/Common/SupplyAPY'
import BorrowAPY from '@/components/Common/BorrowAPY'
import decodingYieldsMatrixApyRender from '@/views/YieldMatrix/decodingYieldsMatrixApyRender'

import { numberFormat } from '@/utils/format'

// import LendIcon from '@/public/image/LENDLE.png'
// import LightningIcon from '@/public/svg/icon-lightning.svg'

type MarketTableProps = {
  markets: Record<Lowercase<string>, Market>
  yieldMatrixData?: any
}

const MarketTable: React.FunctionComponent<MarketTableProps> = ({ markets, yieldMatrixData }) => {
  const router = useRouter()
  const {
    walletAccountStore: { reserves }
  } = useAppState()
  const renderHelpAPY: any = () => (
    <div>
      <div>APY: compounding interest accrued by deposit or borrow.</div>
      <div>APR: non compounding rewards earned as part of liquidity mining incentives.</div>
    </div>
  )

  return (
    <div className="mt-8">
      <div className="card hidden overflow-visible md:block">
        <section className="text600 flex flex-col md:w-full">
          <header className="text600 grid w-full grid-cols-5 items-center px-6 py-3 text-xs [&>th]:font-normal">
            <div className="text-left">Assets</div>
            <div className="pr-[35%] text-left">Total supplied</div>
            <div className="text-center">
              <div className="items-left flex justify-start">
                <span title={renderHelpAPY()}>Supply APY</span>
                {/* <Tooltip
                  containerStyle="ml-2"
                  cloudStyle="w-40 lg:w-96 -left-36"
                  arrowStyle="hidden"
                  type="info"
                  content={renderHelpAPY()}
                /> */}
              </div>
            </div>
            {/* <div className="-m-2 text-left">Loop APY</div>
            <div className="-m-6 text-left">Long/Short APY</div> */}
            <div className="text-center">
              <div className="items-left flex justify-start">
                <span>Borrow APY</span>
                {/* <Tooltip
                  containerStyle="ml-2"
                  cloudStyle="w-40 lg:w-96 -left-36"
                  arrowStyle="hidden"
                  type="info"
                  content={renderHelpAPY()}
                /> */}
              </div>
            </div>
            <div className="text-left">Total borrowed</div>
            {/* <div className="text-left">Utilization Rate</div> */}
            {/* <div className="ml-auto h-[26px] text-right">
              <Observer>
                {() => <VestButton className="h-[26px] min-w-[100px] text-xs font-normal" title="Vest all" />}
              </Observer>
            </div> */}
          </header>

          {isEmpty(markets)
            ? Array(6)
                .fill({})
                .map(
                  (_, index) => <MarketTableSkeleton key={'MarketTableSkeleton' + index} /> // eslint-disable-line
                )
            : Object.entries(markets).map(([key, market]) => {
                const { symbol, address } = market
                const reserve = !isEmpty(reserves) ? reserves[address] : null
                const reserveSupplied = Number(market.supplied.amount) / 10 ** market.supplied.decimals
                const reserveSuppliedRC = reserveSupplied * market.price
                const reserveBorrowed = Number(market.borrowed.amount) / 10 ** market.borrowed.decimals
                const reserveBorrowedRC = reserveBorrowed * market.price
                const yieldLoopItem = yieldMatrixData
                  ? yieldMatrixData
                      .find((row) => row.keyRow === symbol)
                      .valueRow.find((token) => token.quoteToken === symbol)
                  : null
                const yieldLongShortItem = yieldMatrixData
                  ? yieldMatrixData
                      .find((row) => row.keyRow === symbol)
                      .valueRow.reduce((prev, current) => (prev.netApy > current.netApy ? prev : current))
                  : null
                return (
                  <div
                    role="button"
                    aria-hidden="true"
                    onClick={() =>
                      router.push({
                        pathname: '/marketdetail',
                        query: {
                          asset: symbol,
                          contract: key
                        }
                      })
                    }
                    className="grid min-h-[72px] w-full cursor-pointer grid-cols-5 items-center justify-between px-6 text-sm font-semibold last:rounded-b-base last:pb-2 hover:bg-bg-gray-100 dark:hover:bg-white-100 lg:text-base "
                    key={key}
                  >
                    <div className="flex items-center py-3">
                      {symbol && (
                        <div className="flex">
                          <Image src={AssetsIcons[symbol] || AssetsIcons.DEFAULT} alt={symbol} height={32} width={32} />
                        </div>
                      )}
                      <div className="ml-4 flex flex-col">
                        <div className="text900 font-semibold">{symbol}</div>
                        {/* <div className="text600 text-sm font-normal ">
                          ${' '}
                          {numberFormat(market.price, {
                            minDigits: market.price < 10 ? 4 : 0,
                            maxDigits: market.price < 10 ? 4 : 0,
                            isSymbolHide: true
                          })}
                        </div> */}
                      </div>
                    </div>
                    <div className="py-4 text-left">
                      <div className="flex-column pr-[35%] text-left">
                        {/* <div className="text900 font-semibold">
                          {numberFormat(Number(reserveSupplied) + Number(reserveBorrowed), {
                            minDigits: reserveSupplied < 100 ? 2 : 0,
                            maxDigits: reserveSupplied < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div> */}
                        <div className="text900 text-semibold">
                          $
                          {numberFormat(reserveSuppliedRC + reserveBorrowedRC, {
                            maxDigits: reserveSuppliedRC < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="items-center py-3">
                      <SupplyAPY contractAddress={address} symbol={symbol} />
                      {/* <div className="flex items-center">
                        <div className="text900 flex items-center justify-center">
                          <APY contractAddress={address} type="supply" />
                          <div className="ml-1">%</div>
                        </div>
                        <div className="bg100 text700 group relative mx-2 flex min-w-[94px] items-center justify-center rounded-full px-2 py-[2px] text-xs">
                          <div className="mr-1 flex w-3 items-center ">
                            <Image src={LendIcon} width={12} height={12} alt={symbol} />
                          </div>
                          <div className="flex items-center font-normal">
                            <APR contractAddress={address} type="supply" />
                            <span className="ml-[1px]">%</span>
                          </div>
                          <div className="ml-1 font-normal">APR</div>
                          <div className="absolute bottom-0 mb-6 hidden flex-col items-center justify-center pb-4 group-hover:flex">
                            <span className="whitespace-no-wrap relative z-10 w-96 rounded-xl border border-bg-header bg-white-1 p-2 text-xs  leading-none text-textGray-600 shadow-lg dark:text-textGray-900">
                              Participating in this Lendle market gives rewards in LEND tokens. The APR is just a
                              representation on how these rewards would be during a 1-year period
                            </span>
                          </div>
                        </div>
                      </div>
                      */}
                    </div>
                    {/* <div className="-m-2 items-center py-3">
                      {yieldMatrixData && (
                        <Tooltip
                          type="infoModal"
                          cloudStyle="top-1/2 underline-offset-1"
                          arrowStyle="hidden"
                          content={decodingYieldsMatrixApyRender(yieldLoopItem)}
                        >
                          <span className="text900">{numberFormat(yieldLoopItem.netApy)}%</span>
                        </Tooltip>
                      )}
                    </div>
                    <div className="-m-6 items-center py-3">
                      {yieldMatrixData && (
                        <Tooltip
                          type="infoModal"
                          cloudStyle="top-1/2 underline-offset-1"
                          arrowStyle="hidden"
                          content={decodingYieldsMatrixApyRender(yieldLongShortItem)}
                        >
                          <span className="text900">Up to {numberFormat(yieldLongShortItem.netApy)}%</span>
                        </Tooltip>
                      )}
                    </div> */}
                    <div className="items-left py-3">
                      <BorrowAPY contractAddress={address} />
                      {/* <div className="flex items-center">
                        <div className="text900 flex items-center justify-center">
                          <APY contractAddress={address} type="borrow" />
                          <div className="ml-1">%</div>
                        </div>
                        <div className="bg100 group relative mx-2 flex min-w-[94px] items-center justify-center rounded-full px-2 py-[2px] text-xs">
                          <div className="mr-1 flex w-3 items-center ">
                            <Image src={LendIcon} width={12} height={12} alt={symbol} />
                          </div>
                          <div className="text700 flex items-center font-normal">
                            <APR contractAddress={address} type="borrow" />
                            <span className="ml-[1px]">%</span>
                          </div>
                          <div className="text700 ml-1 font-normal">APR</div>
                          <div className="absolute bottom-0 mb-6 hidden flex-col items-center justify-center pb-4 group-hover:flex">
                            <span className="whitespace-no-wrap relative z-10 w-96 rounded-xl border border-bg-header bg-white-1 p-2 text-xs leading-none text-textGray-600 shadow-lg dark:text-textGray-900">
                              Participating in this Lendle market gives rewards in LEND tokens. The APR is just a
                              representation on how these rewards would be during a 1-year period
                            </span>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="py-4 text-left">
                      <div className="flex-column">
                        {/* <div className="text900 font-semibold0">
                          {numberFormat(Number(reserveBorrowed), {
                            minDigits: reserveBorrowed < 100 ? 2 : 0,
                            maxDigits: reserveBorrowed < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div> */}
                        <div className="text900 text-semibold">
                          $
                          {numberFormat(Number(reserveBorrowedRC), {
                            minDigits: reserveBorrowedRC < 100 ? 2 : 0,
                            maxDigits: reserveBorrowedRC < 100 ? 2 : 0,
                            isSymbolHide: true
                          })}
                        </div>
                      </div>
                    </div>
                    {/* <div className="py-4 text-left">
                      <div className="flex-column">
                        {!isEmpty(reserve) ? (
                          <div className="text900 text-semibold">
                            {reserve?.borrowed?.amount && reserve?.supplied?.amount
                              ? numberFormat((Number(reserve.borrowed.amount) * 100) / Number(reserve.supplied.amount))
                              : '0,00'}{' '}
                            %
                          </div>
                        ) : (
                          <Skeleton className="!h-5 rounded-full" text={16} />
                        )}
                      </div>
                    </div> */}
                    {/* <div className="flex items-center text-right md:text-xs lg:text-sm">
                      <div className="ml-auto text-right">
                        <Observer>
                          {() => (
                            <VestButton reserve={address} className="ml-auto !h-7 min-w-[100px] text-xs font-normal" />
                          )}
                        </Observer>
                      </div>
                    </div> */}
                  </div>
                )
              })}
        </section>
      </div>
    </div>
  )
}

export default observer(MarketTable)

const MarketTableSkeleton = () => (
  <div className="grid w-full cursor-pointer grid-cols-8 items-center justify-between px-6 text-sm font-semibold text-white-1 last:rounded-b-base hover:bg-bg-gray-100 dark:hover:bg-white-100">
    <div aria-label="loading">
      <div className="flex items-center justify-start gap-4 py-[19px]">
        <Skeleton text={8} className="!h-8 !w-8 rounded-full" />
        <div className="flex flex-col gap-1">
          <Skeleton text={10} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="flex items-end justify-start gap-4 py-4">
        <div className="flex flex-1 flex-col items-start gap-1 text-left">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="flex items-center justify-start gap-4 py-4">
        <div className="flex flex-1 items-center gap-1 text-left">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="-m-2 flex items-center justify-start gap-4 py-4">
        <div className="flex flex-1 items-center gap-1 text-left">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="-m-2 flex items-center justify-start gap-4 py-4">
        <div className="flex flex-1 items-center gap-1 text-left">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="flex items-center justify-start gap-4 py-4">
        <div className="flex flex-1 items-center gap-1 text-left">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="flex items-end justify-start gap-4 py-4">
        <div className="flex flex-1 flex-col items-start gap-1 pr-[35%] text-left">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    <div aria-label="loading">
      <div className="flex items-end justify-start gap-4 py-4">
        <div className="flex flex-1 flex-col items-end gap-1 pr-[35%] text-center">
          <Skeleton text={16} className="!h-5" />
        </div>
      </div>
    </div>
    {/* <div aria-label="loading">
      <div className="flex items-end justify-end gap-4 py-4">
        <div className="flex flex-1 flex-col items-end gap-1 text-right">
          <Skeleton text={14} className="!h-[26px] !w-[100px] rounded-full" />
        </div>
      </div>
    </div> */}
  </div>
)
