import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { isEmpty } from 'lodash'
import { useAppState } from '@/stores'

import ClientOnlyRender from '@/components/Common/ClientOnlyRender'
import { incentiveDataEnum } from '@/config/constants/assets'
import MarketTable from './components/MarketTable/MarketTable'
import MarketColumnMobile from './components/MarketTable/MarketColumn'

const MarketContent: React.FunctionComponent = () => {
  const {
    walletAccountStore: { markets, reserves, methApy, aPYs, aPRs }
  } = useAppState()

  const marketsMatrixData = useMemo(() => {
    if (isEmpty(reserves)) return null

    const { secondsPerYear } = incentiveDataEnum
    const marketsMatrixData = Object.values(reserves).map((market) => {
      const { symbol, address } = market
      const { supply: supplyApy, borrow: borrowApy } = aPYs[address]
      const { supply: supplyRewardApr, borrow: borrowRewardApr } = aPRs[address]
      const supplyRewardApy = ((1 + supplyRewardApr / secondsPerYear) ** secondsPerYear - 1) * 100
      const borrowRewardApy = ((1 + borrowRewardApr / secondsPerYear) ** secondsPerYear - 1) * 100

      const supplyLiquidStakingApy = symbol.toLowerCase().includes('meth') && methApy ? methApy : 0
      const borrowLiquidStakingApy = symbol.toLowerCase().includes('meth') && methApy ? methApy : 0

      const maximumLTV = Number(market.configuration.ltv) / 100
      const maxLeverage = 1 / (1 - maximumLTV / 100)

      return {
        ...market,
        supplyRewardApy,
        borrowRewardApy,
        borrowApy,
        supplyApy,
        symbol,
        maximumLTV,
        maxLeverage,
        supplyLiquidStakingApy,
        borrowLiquidStakingApy
      }
    })
    return marketsMatrixData
  }, [reserves, methApy, aPYs, aPRs])

  const yieldMatrixData = useMemo(() => {
    if (isEmpty(marketsMatrixData)) return null
    const yieldMatrixData = marketsMatrixData.map((baseMarket) => {
      const yieldsRow = marketsMatrixData.map((quoteMarket) => {
        const leverageRate = 1 + baseMarket.maxLeverage * (baseMarket.maximumLTV / 100)
        const totalSupplyApy = leverageRate * baseMarket.supplyApy
        const totalBorrowApy = (leverageRate - 1) * quoteMarket.borrowApy
        const netOrganicApy = totalSupplyApy - totalBorrowApy
        const netRewardApy =
          leverageRate * baseMarket.supplyRewardApy +
          (leverageRate - 1) * quoteMarket.borrowRewardApy +
          leverageRate * baseMarket.supplyLiquidStakingApy -
          (leverageRate - 1) * quoteMarket.borrowLiquidStakingApy
        const netApy = netOrganicApy + netRewardApy
        const currentSupplyApy = baseMarket.supplyApy + baseMarket.supplyRewardApy + baseMarket.supplyLiquidStakingApy
        const currentBorrowApy =
          quoteMarket.borrowApy - quoteMarket.borrowRewardApy - quoteMarket.borrowLiquidStakingApy

        return {
          quoteToken: quoteMarket.symbol,
          netApy,
          baseToken: baseMarket.symbol,
          leverageRate,
          maxLeverage: baseMarket.maxLeverage,
          currentSupplyApy,
          currentBorrowApy,
          netOrganicApy,
          netRewardApy
        }
      })
      const sortYieldsRow = yieldsRow.sort((a, b) => b.netApy - a.netApy)
      return { keyRow: baseMarket.symbol, valueRow: sortYieldsRow }
    })
    const sortYieldMatrixData = yieldMatrixData.sort((a, b) => b.valueRow[0].netApy - a.valueRow[0].netApy)
    return sortYieldMatrixData
  }, [marketsMatrixData])

  return (
    <ClientOnlyRender>
      <>
        <MarketTable markets={markets} yieldMatrixData={yieldMatrixData} />
        <MarketColumnMobile markets={markets} yieldMatrixData={yieldMatrixData} />
      </>
    </ClientOnlyRender>
  )
}

export default observer(MarketContent)
